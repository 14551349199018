var render = function () {
  var _vm$sandwichLeaveCoun, _vm$sandwichLeaveCoun2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.LEAVE_REQUESTS_APPLICATION_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 ml-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showApplicationCreateModal
    }
  }, [_vm._v(" Apply For Employee ")])] : [_vm.$permissionAbility(_vm.LEAVE_REQUESTS_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Apply ")])] : _vm._e()]], 2)]), _c('vue-good-table', {
    attrs: {
      "style-class": "vgt-table striped table-custom-style",
      "line-numbers": false,
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row, _props$row2, _props$row2$user, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row7$leaveType, _props$row7$leaveType2, _props$row8, _props$row9, _props$row10, _props$row11, _props$row12, _props$row13, _props$row14, _props$row15, _props$row16;
        return [props.column.field === 'name' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.user_id ? [_c('UserCard', {
          attrs: {
            "user": props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$user = _props$row2.user) === null || _props$row2$user === void 0 ? void 0 : _props$row2$user.data
          }
        })] : _vm._e()], 2) : _vm._e(), props.column.field === 'leave_days_format' ? _c('span', [props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.leave_days ? [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.leave_days))])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_is_reconciliation' ? _c('span', [(_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.is_reconciliation ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" Yes ")])] : [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" No ")])]], 2) : _vm._e(), props.column.field === 'format_leave_type' ? _c('div', [(_props$row6 = props.row) !== null && _props$row6 !== void 0 && _props$row6.leaveType ? [_c('b-badge', {
          staticClass: "ml-1",
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" " + _vm._s((_props$row7 = props.row) === null || _props$row7 === void 0 ? void 0 : (_props$row7$leaveType = _props$row7.leaveType) === null || _props$row7$leaveType === void 0 ? void 0 : (_props$row7$leaveType2 = _props$row7$leaveType.data) === null || _props$row7$leaveType2 === void 0 ? void 0 : _props$row7$leaveType2.name) + " ")])] : _vm._e()], 2) : _vm._e(), props.column.field === 'format_status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant((_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.status)
          }
        }, [_vm._v(" " + _vm._s((_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.status) + " ")]), _c('div', [((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.remarks) !== '' ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Remarks: ' + ((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.remarks),
            expression: "'Remarks: '+props.row?.remarks",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon m-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (_props$row12 = props.row) !== null && _props$row12 !== void 0 && _props$row12.reject_remarks ? [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Rejection Note: ' + ((_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.reject_remarks),
            expression: "'Rejection Note: '+props.row?.reject_remarks",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "btn-icon m-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.leave_request_file) !== '' ? [_c('b-link', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: 'Attached File',
            expression: "'Attached File'",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          attrs: {
            "href": props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.leave_request_file,
            "target": "_blank"
          }
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon m-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('feather-icon', {
          staticClass: "btn-icon",
          attrs: {
            "icon": "FileIcon"
          }
        })], 1)], 1)] : _vm._e()], 2)], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [((_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.status) === _vm.PENDING ? [_vm.$permissionAbility(_vm.LEAVE_REQUESTS_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAVE_REQUESTS_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()] : [_c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("N/A")])]], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-leave-request-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Application Edit' : 'Apply Leave',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leaveRequestValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Leave Type",
      "label-for": "leave-type-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "leave type",
      "vid": "leave_type_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "leave-type-id",
            "placeholder": "Select Leave Type",
            "options": _vm.leaveTypesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "selectable": function selectable(option) {
              return option.disable;
            }
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectLeaveTypeId,
            callback: function callback($$v) {
              _vm.selectLeaveTypeId = $$v;
            },
            expression: "selectLeaveTypeId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Start Date",
      "label-for": "start-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start date",
      "vid": "start_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "start-date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "reset-button": "",
            "date-disabled-fn": _vm.dateDisabledEmp
          },
          on: {
            "context": _vm.onContextStartDate,
            "input": function input($event) {
              _vm.onChangeGetLeaveDates();
              _vm.validateHalfDayOptions();
            }
          },
          model: {
            value: _vm.start_date,
            callback: function callback($$v) {
              _vm.start_date = $$v;
            },
            expression: "start_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "start_date_half_day_leave_checkbox"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isCheckedStartDaySwitch,
      "name": "start_date_half_day_leave_checkbox",
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(isChecked) {
        _vm.onChangeStartDateSwitch(isChecked);
        _vm.onChangeGetLeaveDates();
        _vm.validateHalfDayOptions();
      }
    }
  })], 1), _vm.isCheckedStartDaySwitch ? [_c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "start_date_half_day_leave"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start_date_half_day_leave",
      "vid": "start_date_half_day_leave"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "id": "start_date_half_day_leave",
            "placeholder": "Choose a option",
            "options": _vm.startDateHalfDayLeaveOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectStartDateHalfDayLeaveValue,
            callback: function callback($$v) {
              _vm.selectStartDateHalfDayLeaveValue = $$v;
            },
            expression: "selectStartDateHalfDayLeaveValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3193440304)
  })], 1)] : _vm._e(), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "End Date",
      "label-for": "end-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "end date",
      "vid": "end_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "end-date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "reset-button": "",
            "date-disabled-fn": _vm.dateDisabledEmp
          },
          on: {
            "context": _vm.onContextEndDate,
            "input": function input($event) {
              _vm.onChangeGetLeaveDates();
              _vm.validateHalfDayOptions();
            }
          },
          model: {
            value: _vm.end_date,
            callback: function callback($$v) {
              _vm.end_date = $$v;
            },
            expression: "end_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "end_date_half_day_leave_checkbox"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isCheckedEndDaySwitch,
      "name": "end_date_half_day_leave_checkbox",
      "disabled": _vm.endDateHalfDayLeaveCheckboxDisable,
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(isChecked) {
        _vm.onChangeEndDateSwitch(isChecked);
        _vm.onChangeGetLeaveDates();
        _vm.validateHalfDayOptions();
      }
    }
  })], 1), _vm.isCheckedEndDaySwitch ? [_c('b-form-group', {
    attrs: {
      "label-for": "end_date_half_day_leave"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "end_date_half_day_leave",
      "vid": "end_date_half_day_leave"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-select', {
          attrs: {
            "id": "end_date_half_day_leave",
            "placeholder": "Choose a option",
            "options": _vm.endDateHalfDayLeaveOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectEndDateHalfDayLeaveValue,
            callback: function callback($$v) {
              _vm.selectEndDateHalfDayLeaveValue = $$v;
            },
            expression: "selectEndDateHalfDayLeaveValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3363407455)
  })], 1)] : _vm._e(), _vm.isLeaveCalculateSubmitLoading ? [_c('b-form-group', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Text Centered"
    }
  })], 1)])] : [_c('b-form-group', [_c('div', [_vm.leaveCount > 0 ? _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Days: " + _vm._s(_vm.leaveCount) + " ")]) : _vm._e(), _vm.sandwichLeaveCount > 0 ? _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Sandwich Days: " + _vm._s((_vm$sandwichLeaveCoun = _vm.sandwichLeaveCount) !== null && _vm$sandwichLeaveCoun !== void 0 ? _vm$sandwichLeaveCoun : 0) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap justify-content-start align-items-center"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Dates: "), _vm.leaveDates.length > 0 ? [_vm._l(_vm.leaveDates, function (leaveDate, index) {
    return [_c('b-badge', {
      key: index,
      attrs: {
        "pill": "",
        "variant": "light-primary"
      }
    }, [leaveDate ? [_vm._v(" " + _vm._s(_vm._f("moment")(leaveDate, "ll")) + " ")] : _vm._e()], 2)];
  })] : [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Empty ")])]], 2)])])])], _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Reason",
      "label-for": "reason"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "reason",
      "vid": "reason",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "reason",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Reason",
            "rows": "3"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "File",
      "label-for": "file"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "file",
      "rules": "size:2048",
      "vid": "leave_request_file"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "file",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Choose a file or drop it here...",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isLeaveRequestFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1), _vm.$permissionAbility(_vm.LEAVE_REQUESTS_APPLICATION_CREATE, _vm.permissions) ? [_c('b-modal', {
    attrs: {
      "id": "modal-leave-application-create-form",
      "centered": "",
      "title": "Apply Leave",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenApplicationCreateModal
    }
  }, [_c('validation-observer', {
    ref: "leaveApplicationCreateValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.applicationCreateForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Employee",
      "label-for": "user-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "employee",
      "vid": "user_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Select Employee",
            "options": _vm.userIdOption,
            "reduce": function reduce(country) {
              return country.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.onChangeEmployee
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('UserSelect', {
                attrs: {
                  "user": data
                }
              })];
            }
          }], null, true),
          model: {
            value: _vm.selectUserId,
            callback: function callback($$v) {
              _vm.selectUserId = $$v;
            },
            expression: "selectUserId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2080778975)
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Leave Type",
      "label-for": "employee-leave-type-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Leave Type",
      "vid": "leave_type_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          attrs: {
            "id": "employee-leave-type-id",
            "placeholder": "Select Leave Type",
            "options": _vm.employeeLeaveTypesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "selectable": function selectable(option) {
              return option.disable;
            },
            "disabled": _vm.employeeLeaveTypesOption.length <= 0
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectLeaveTypeId,
            callback: function callback($$v) {
              _vm.selectLeaveTypeId = $$v;
            },
            expression: "selectLeaveTypeId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1664117419)
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Start Date",
      "label-for": "start-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start date",
      "vid": "start_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "start-date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "reset-button": "",
            "date-disabled-fn": _vm.dateDisabled
          },
          on: {
            "context": _vm.onContextStartDate,
            "input": function input($event) {
              _vm.onChangeGetLeaveDates();
              _vm.validateHalfDayOptions();
            }
          },
          model: {
            value: _vm.start_date,
            callback: function callback($$v) {
              _vm.start_date = $$v;
            },
            expression: "start_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2161395339)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "start_date_half_day_leave_checkbox"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isCheckedStartDaySwitch,
      "name": "start_date_half_day_leave_checkbox",
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(isChecked) {
        _vm.onChangeStartDateSwitch(isChecked);
        _vm.onChangeGetLeaveDates();
        _vm.validateHalfDayOptions();
      }
    }
  })], 1), _vm.isCheckedStartDaySwitch ? [_c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "start_date_half_day_leave"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "start_date_half_day_leave",
      "vid": "start_date_half_day_leave"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('v-select', {
          attrs: {
            "id": "start_date_half_day_leave",
            "placeholder": "Choose a option",
            "options": _vm.startDateHalfDayLeaveOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectStartDateHalfDayLeaveValue,
            callback: function callback($$v) {
              _vm.selectStartDateHalfDayLeaveValue = $$v;
            },
            expression: "selectStartDateHalfDayLeaveValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3193440304)
  })], 1)] : _vm._e(), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "End Date",
      "label-for": "end-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "end date",
      "vid": "end_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "end-date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "reset-button": "",
            "date-disabled-fn": _vm.dateDisabled
          },
          on: {
            "context": _vm.onContextEndDate,
            "input": function input($event) {
              _vm.onChangeGetLeaveDates();
              _vm.validateHalfDayOptions();
            }
          },
          model: {
            value: _vm.end_date,
            callback: function callback($$v) {
              _vm.end_date = $$v;
            },
            expression: "end_date"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1846526628)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Half Day Leave",
      "label-for": "end_date_half_day_leave_checkbox"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isCheckedEndDaySwitch,
      "name": "end_date_half_day_leave_checkbox",
      "disabled": _vm.endDateHalfDayLeaveCheckboxDisable,
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(isChecked) {
        _vm.onChangeEndDateSwitch(isChecked);
        _vm.onChangeGetLeaveDates();
        _vm.validateHalfDayOptions();
      }
    }
  })], 1), _vm.isCheckedEndDaySwitch ? [_c('b-form-group', {
    attrs: {
      "label-for": "end_date_half_day_leave"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "end_date_half_day_leave",
      "vid": "end_date_half_day_leave"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('v-select', {
          attrs: {
            "id": "end_date_half_day_leave",
            "placeholder": "Choose a option",
            "options": _vm.endDateHalfDayLeaveOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          on: {
            "input": _vm.onChangeGetLeaveDates
          },
          model: {
            value: _vm.selectEndDateHalfDayLeaveValue,
            callback: function callback($$v) {
              _vm.selectEndDateHalfDayLeaveValue = $$v;
            },
            expression: "selectEndDateHalfDayLeaveValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3363407455)
  })], 1)] : _vm._e(), _vm.isLeaveCalculateSubmitLoading ? [_c('b-form-group', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "primary",
      "label": "Text Centered"
    }
  })], 1)])] : [_c('b-form-group', [_c('div', [_vm.leaveCount > 0 ? _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Days: " + _vm._s(_vm.leaveCount) + " ")]) : _vm._e(), _vm.sandwichLeaveCount > 0 ? _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Sandwich Days: " + _vm._s((_vm$sandwichLeaveCoun2 = _vm.sandwichLeaveCount) !== null && _vm$sandwichLeaveCoun2 !== void 0 ? _vm$sandwichLeaveCoun2 : 0) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap justify-content-start align-items-center"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" Dates: "), _vm.leaveDates.length > 0 ? [_vm._l(_vm.leaveDates, function (leaveDate, index) {
    return [_c('b-badge', {
      key: index,
      attrs: {
        "pill": "",
        "variant": "light-primary"
      }
    }, [leaveDate ? [_vm._v(" " + _vm._s(_vm._f("moment")(leaveDate, "ll")) + " ")] : _vm._e()], 2)];
  })] : [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(" Empty ")])]], 2)])])])], _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Reason",
      "label-for": "remarks"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "remarks",
      "vid": "remarks",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "remarks",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Reason of Leave",
            "rows": "3"
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 698375530)
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Attachment",
      "label-for": "file"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "attachment",
      "rules": "size:2048",
      "vid": "leave_request_file"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-file', {
          attrs: {
            "id": "file",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Attach a File Or Drop it Here",
            "drop-placeholder": "Drop file here..."
          },
          model: {
            value: _vm.file,
            callback: function callback($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2275222425)
  })], 1), _vm.canApprove() ? _c('b-form-group', {
    attrs: {
      "label": "Approve"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": _vm.isApprove,
      "name": "approve",
      "switch": "",
      "inline": ""
    },
    on: {
      "change": function change(isChecked) {
        return _vm.isApprove = isChecked;
      }
    }
  })], 1) : _vm._e(), _vm.isLeaveRequestFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)] : _vm._e(), _vm.$permissionAbility(_vm.LEAVE_REQUESTS_RECONCILIATION, _vm.permissions) ? [_c('b-modal', {
    attrs: {
      "id": "modal-leave-reconciliation-form",
      "centered": "",
      "title": "Leave Reconciliation",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "leaveReconciliationValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationLeaveReconciliationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reconciliation Note",
      "label-for": "reconciliation-note"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "reconciliation_note",
      "vid": "reconciliation_note",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "id": "reconciliation-note",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Reconciliation Note",
            "rows": "3"
          },
          model: {
            value: _vm.reconciliationNote,
            callback: function callback($$v) {
              _vm.reconciliationNote = $$v;
            },
            expression: "reconciliationNote"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2306522118)
  })], 1), _vm.isLeaveReconciliationFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }