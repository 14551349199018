export const APPROVED = "Approved";
export const PENDING = "Pending";
export const REJECTED = "Rejected";
export const CANCELED = "Canceled";

export const leaveStatusConstants = [
  { name: "Rejected", value: "Rejected", variant: "gradient-danger", badgeVariant: "light-danger" },
  { name: "Pending", value: "Pending", variant: "gradient-warning", badgeVariant: "light-warning" },
  { name: "Approved", value: "Approved", variant: "gradient-success", badgeVariant: "light-success" },
  { name: "Reverted", value: "Reverted", variant: "gradient-warning", badgeVariant: "light-warning" },
];
